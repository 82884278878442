import React, { useCallback, useEffect, useRef, useState } from 'react';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import axios, { CancelToken } from 'axios';
import { getParamString } from '@utilities/getParamString';
import Screen from '@components/Screen/Screen';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';
import Button from '@components/Button/Button';
import Collection from '@components/Collection/Collection';
import Filters from './components/Filters/Filters';
import OrdersListItem from '@components/OrdersListItem/OrdersListItem';
import NewOrderModal from '@components/NewOrderModal/NewOrderModal';
import { useSelector } from 'react-redux';
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';

/**
 * Items per Page
 */

const perPage = 10;

/**
 * Sort Options
 */

const sortOptions = [
	{ label: 'Order Name', value: 'order_name', sort: 'ASC' },
	{ label: 'Fire Department', value: 'fire_department_name', sort: 'ASC' },
	{ label: 'Price', value: 'price', sort: 'DESC' },
	{ label: 'Last Modified', value: 'order_modified_timestamp', sort: 'DESC' },
	{
		label: 'Date Submitted',
		value: 'order_submitted_timestamp',
		sort: 'DESC',
	},
	{
		label: 'Most Recent',
		value: 'order_created_timestamp',
		sort: 'DESC',
	},
	{
		label: 'Oldest',
		value: 'order_created_timestamp ',
		sort: 'ASC',
	},
];

/**
 * Filters Schema
 */

const filtersSchema = {
	'order_name': '',
	'order_status': '',
	'order_firewriter_number': '',
	'order_po_number': '',
	'fire_department_id': '',
	'user_id': '',
	'organization_id': '',
};

const Orders = () => {
	const fetchRequestSource = useRef(null);
	const [orders, setOrders] = useState([]);
	const [page, setPage] = useState(0);
	const [isLastPage, setIsLastPage] = useState(false);
	const [sortField, setSortField] = useState('');
	const [sortDirection, setSortDirection] = useState('DESC');
	const isDistributorAdmin = useSelector((state) =>
		state.auth.user?.permissions?.includes('manage_organization'),
	);
	const user = useSelector((state) => state.auth.user);
	const [filters, setFilters] = useState({
		...filtersSchema,
		user_id: isDistributorAdmin ? user?.user_id : null,
	});
	const [initialized, setInitialized] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [modalActive, setModalActive] = useState(false);
	const [orderPendingDelete, setOrderPendingDelete] = useState(null);
	const [orderPendingCancel, setOrderPendingCancel] = useState(null);

	/**
	 * Fetch Orders
	 */

	const fetchOrders = useCallback(
		(
			filterString = '',
			paginationString = '',
			sortField = null,
			sortDirection = null,
		) => {
			// cancel any pending request
			fetchRequestSource.current?.cancel();
			// create new cancel token
			fetchRequestSource.current = CancelToken.source();

			setIsFetching(true);

			return http()
				.get(
					`${API_ROOT}/orders?${filterString}${paginationString}&sort=${sortField}&direction=${sortDirection}`,
					{ cancelToken: fetchRequestSource.current?.token },
				)
				.then(({ data }) => {
					setIsFetching(false);
					return data.orders;
				})
				.catch((err) => {
					if (!axios.isCancel(err)) {
						setIsFetching(false);
					}
					return [];
				})
				.finally(() => {
					setInitialized(true);
				});
		},
		[],
	);

	/**
	 * Reset Page on Sort/Filter change
	 */

	useEffect(() => {
		setPage(0);
		setIsLastPage(false);
	}, [sortField, sortDirection, filters]);

	/**
	 * Fetch Orders on Sort/Filter/Page Change
	 */

	useEffect(() => {
		fetchOrders(
			// filters
			getParamString(filters),
			// pagination
			getParamString({ take: perPage, skip: page * perPage }),
			// sort
			sortField,
			sortDirection,
		).then((orders) => {
			setIsLastPage(orders.length < perPage);
			setOrders((current) => [...(page === 0 ? [] : current), ...orders]);
		});
	}, [fetchOrders, sortField, sortDirection, filters, page]);

	/**
	 * Handle 'New Order' Modal Close
	 */

	const handleModalClose = useCallback(
		(order) => {
			if (order) {
				fetchOrders(
					// filters
					getParamString(filters),
					// pagination
					getParamString({ take: perPage, skip: page * perPage }),
					// sort
					sortField,
					sortDirection,
				).then((orders) => {
					setIsLastPage(orders.length < perPage);
					setOrders((current) => [...(page === 0 ? [] : current), ...orders]);
				});
			}

			setModalActive(false);
		},
		[fetchOrders, filters, sortField, sortDirection, page],
	);

	/**
	 * Handle remove order
	 */

	const handleRemove = useCallback((order) => {
		setIsFetching(true);
		return http()
			.delete(`${API_ROOT}/orders/${order.order_id}`)
			.then(() => {
				window.scrollTo({ top: 0 });
				// trigger refetch
				fetchOrders(
					// filters
					getParamString(filters),
					// pagination
					getParamString({ take: perPage, skip: page * perPage }),
					// sort
					sortField,
					sortDirection,
				).then((orders) => {
					setIsLastPage(orders.length < perPage);
					setOrders((current) => [...(page === 0 ? [] : current), ...orders]);
				});

				setFilters((filters) => ({ ...filters }));
				// close modal
				setIsFetching(null);
				setOrderPendingDelete(null);
			});
	}, [fetchOrders, filters, sortDirection, sortField, page]);


	/**
	 * Handle cancel order
	 */

	const handleCancel = useCallback((order) => {
		return;
		// return http()
		// 	.post(`${API_ROOT}/orders/cancel/${order.order_id}`)
		// 	.then(() => {
		// 		setOrders(orders.map((o) => {
		// 			if(o.order_id === order.order_id) {
		// 				o['order_status'] = 'Cancelled';
		// 				return o;
		// 			}
		// 			return o;
		// 		}));
		// 		setOrderPendingCancel(null);
		// 	});
	}, [orders]);

	return (
		<Screen
			headerContent={
				<>
					<WelcomeHeader />
					<Filters filters={filters} setFilters={setFilters} />
				</>
			}
			loading={isFetching && !initialized}>
			<Collection
				title="Orders"
				items={orders}
				component={OrdersListItem}
				page={page}
				onPageChange={setPage}
				isLastPage={isLastPage}
				sortField={sortField}
				sortDirection={sortDirection}
				sortOptions={sortOptions}
				onSortChange={([field, direction]) => {
					setSortField(field);
					setSortDirection(direction);
				}}
				onAddItem={() => setModalActive(true)}
				isFetching={isFetching}
				onRemove={setOrderPendingDelete}
				onCancelOrder={setOrderPendingCancel}
				noResultsAction={
					<Button
						variant="outlined"
						onClick={() => setModalActive(true)}>
						Create New Order
					</Button>
				}
			/>
			<ConfirmationModal
				active={!!orderPendingDelete}
				title="Delete Order"
				message="Are you sure you want to delete this order?"
				onClose={() => setOrderPendingDelete(null)}
				onConfirm={() => handleRemove(orderPendingDelete)}
			/>
			<ConfirmationModal
				active={!!orderPendingCancel}
				title="Cancel Order"
				message="Are you sure you want to cancel this order?"
				onClose={() => setOrderPendingCancel(null)}
				onConfirm={() => handleCancel(orderPendingCancel)}
			/>
			<NewOrderModal active={modalActive} onClose={handleModalClose} />
		</Screen>
	);
};

export default Orders;
