import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	error: false,
	errorInformation: {
		errorTile: null,
		errorMessage: null,
		errorType: null,
		errorAction() {},
		errorIgnoreAction() {}
	},
};

const errorSlice = createSlice({
	name: 'error',
	initialState,
	reducers: {
		setError(state, action) {
			let errorInfo = action.payload;
			if(state.errorInformation.errorMessage && action.payload.errorMessage) {
				errorInfo.errorMessage = state.errorInformation.errorMessage + '</br><hr>' + action.payload.errorMessage;
			}
			return {
				...state,
				error: true,
				errorInformation: errorInfo
			};
		},
		resetError() {
			return initialState;
		},
	},
});

export const { setError, resetError } = errorSlice.actions;
export default errorSlice.reducer;
