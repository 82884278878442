import { useCallback, useEffect, useRef, useState } from 'react';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import { CancelToken } from 'axios';
import { getParamString } from '@utilities/getParamString';
import Screen from '@components/Screen/Screen';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';
import Collection from '@components/Collection/Collection';
import NotificationsListItem from '@components/NotificationsListItem/NotificationsListItem';
import styles from './Notifications.module.scss';
import { useSelector,useDispatch } from 'react-redux';
import Auth, { acknowledgeNotifications } from '@store/reducers/auth';
/**
 * Items per Page
 */

const perPage = 5;

/**
 * Sort Options
 */

const sortOptions = [
	{ label: 'Quote Update', value: 'quote_update', sort: 'DESC' },
	{ label: 'Order Name', value: 'order_name', sort: 'ASC' },
	{ label: 'Fire Department', value: 'fire_department_name', sort: 'ASC' },
	{ label: 'Price', value: 'price', sort: 'DESC' },
	{ label: 'Last Modified', value: 'order_modified_timestamp', sort: 'DESC' },
	{
		label: 'Date Submitted',
		value: 'order_submitted_timestamp',
		sort: 'DESC',
	},
];

/**
 * Filter Options
 */

const filterOptions = [
	{ label: 'All', value: 'all' },
	{ label: 'Quotes', value: 'quote-update' },
	{ label: 'Orders', value: 'order-status' },
];

const newFilterOptions = [
	{ label: 'All', value: 'all' },
	{ label: 'News', value: 'news' },
	{ label: 'New Features', value: 'general' },
	{ label: 'Option Updates', value: 'option-update' },
	{ label: 'New Fire Departments', value: 'new-fire-department' },
];

const Notifications = () => {
	const { user } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const newsFetchRequestSource = useRef(null);
	const quotesFetchRequestSource = useRef(null);
	const [newsNotifications, setNewsNotifications] = useState([]);
	const [quoteNotifications, setQuoteNotifications] = useState([]);
	const [newsPage, setNewsPage] = useState(0);
	const [quotesPage, setQuotesPage] = useState(0);
	const [isLastNewsPage, setIsLastNewsPage] = useState(false);
	const [isLastQuotesPage, setIsLastQuotesPage] = useState(false);
	const [sortField, setSortField] = useState('');
	const [sortDirection, setSortDirection] = useState('DESC');
	const [filterField, setFilterField] = useState('all');
	const [newsFilterField, setNewsFilterField] = useState('all');
	const [initialized, setInitialized] = useState(false);
	const [isFetchingNews, setIsFetchingNews] = useState(false);
	const [isFetchingQuotes, setIsFetchingQuotes] = useState(false);
	/**
	 * Fetch Notifications
	 */

	useEffect(() => {
		if (user && user.latestNotification && user.hasNotifications) {
			dispatch(acknowledgeNotifications());
			http().post(`${API_ROOT}/notifications/acknowledge`,{'latest_id':user.latestNotification}).catch(() => {});
		}
	}, [dispatch, user]);

	const fetchNewsNotifications = useCallback(
		(
			paginationString = '',
			sortField = null,
			sortDirection = null,
			filterField = null,
		) => {
			// cancel any pending request
			newsFetchRequestSource.current?.cancel();
			// create new cancel token
			newsFetchRequestSource.current = CancelToken.source();

			setIsFetchingNews(true);

			return http()
				.get(
					`${API_ROOT}/notifications?${paginationString}&type=news&sort=${sortField}&direction=${sortDirection}&filter=${filterField}`,
					{ cancelToken: newsFetchRequestSource.current?.token },
				)
				.then(({ data }) => data.notifications)
				.catch(() => {
					return [];
				})
				.finally(() => {
					setInitialized(true);
					setIsFetchingNews(false);
				});
		},
		[],
	);

	const fetchQuoteNotifications = useCallback(
		(
			paginationString = '',
			sortField = null,
			sortDirection = null,
			filterField = null,
		) => {
			// cancel any pending request
			quotesFetchRequestSource.current?.cancel();
			// create new cancel token
			quotesFetchRequestSource.current = CancelToken.source();

			setIsFetchingQuotes(true);

			return http()
				.get(
					`${API_ROOT}/notifications?${paginationString}&type=quote&sort=${sortField}&direction=${sortDirection}&filter=${filterField}`,
					{ cancelToken: quotesFetchRequestSource.current?.token },
				)
				.then(({ data }) => data.notifications)
				.catch(() => {
					return [];
				})
				.finally(() => {
					setInitialized(true);
					setIsFetchingQuotes(false);
				});
		},
		[],
	);

	/**
	 * Reset Page on Sort/Filter change
	 */

	useEffect(() => {
		setQuotesPage(0);
		setIsLastQuotesPage(false);
	}, [sortField, sortDirection]);

	/**
	 * Fetch Notifications on Sort/Filter/Page Change
	 */

	useEffect(() => {
		fetchNewsNotifications(
			// pagination
			getParamString({ take: perPage, skip: newsPage * perPage }),
			null,
			null,
			newsFilterField,
		).then((notifications) => {
			setIsLastNewsPage(notifications.length < perPage);
			setNewsNotifications((current) => [
				...(newsPage === 0 ? [] : current),
				...notifications,
			]);
		});
		
	}, [fetchNewsNotifications, newsPage,newsFilterField]);

	useEffect(() => {
		fetchQuoteNotifications(
			// pagination
			getParamString({ take: perPage, skip: quotesPage * perPage }),
			sortField,
			sortDirection,
			filterField,
		).then((notifications) => {
			setIsLastQuotesPage(notifications.length < perPage);
			setQuoteNotifications((current) => [
				...(quotesPage === 0 ? [] : current),
				...notifications,
			]);
		});
	}, [fetchQuoteNotifications, filterField, quotesPage, sortDirection, sortField]);

	/**
	 * Handle Dismiss Notification
	 */

	const handleNotificationDismiss = useCallback(async (id) => {
		return http()
			.post(`${API_ROOT}/notifications/${id}`, {
				notification: {
					user_notification_dismissed: true,
				},
			})
			.then(() => {
				setNewsNotifications((newsNotifications) =>
					newsNotifications.filter(
						(notification) => notification.notification_id !== id,
					),
				);
				setQuoteNotifications((quoteNotifications) =>
					quoteNotifications.filter(
						(notification) => notification.notification_id !== id,
					),
				);
			});
	}, []);

	return (
		<Screen
			headerContent={
				<>
					<WelcomeHeader />
				</>
			}
			loading={(isFetchingNews || isFetchingQuotes) && !initialized}>
			<Collection
				title="Fire-Dex Updates"
				items={newsNotifications}
				component={NotificationsListItem}
				page={newsPage}
				onPageChange={setNewsPage}
				filterField={newsFilterField}
				filterOptions={newFilterOptions}
				filterPlaceholder="Type"
				onFilterChange={(field) => {
					setNewsPage(0);
					setNewsFilterField(field);
				}}
				isLastPage={isLastNewsPage}
				isFetching={isFetchingNews}
				onRemove={handleNotificationDismiss}
				collectionCardClassName={styles.notificationsCard}
				collectionCardHeaderClassName={styles.notificationsCardHeader}
				collectionListContainerClassName={
					styles.notificationsListContainer
				}
				pageOnScroll={false}
			/>
			<Collection
				title="Quote and Order Updates"
				items={quoteNotifications}
				component={NotificationsListItem}
				page={quotesPage}
				onPageChange={setQuotesPage}
				isLastPage={isLastQuotesPage}
				filterField={filterField}
				filterOptions={filterOptions}
				filterPlaceholder="Type"
				onFilterChange={(field) => {
					setSortField('');
					setSortDirection('DESC');
					setQuotesPage(0);
					setFilterField(field);
				}}
				isFetching={isFetchingQuotes}
				onRemove={handleNotificationDismiss}
				collectionCardClassName={styles.notificationsCard}
				collectionCardHeaderClassName={styles.notificationsCardHeader}
				collectionListContainerClassName={
					styles.notificationsListContainer
				}
				pageOnScroll={false}
			/>
		</Screen>
	);
};

export default Notifications;
