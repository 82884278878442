import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setConfiguratorOptions } from '@store/reducers/configurator';
import classNames from 'classnames';
import NotificationType from '@components/NotificationType/NotificationType';
import ArrowCircleIcon from '@components/Icons/ArrowCircleIcon';
import Menu from '@components/Menu/Menu';
import styles from './NotificationsListItem.module.scss';
import { API_ROOT } from '@config';

import OrderStatusNotification from './OrderStatus/OrderStatusNotification';
import QuoteShareNotification from './QuoteShare/QuoteShareNotification';
import QuoteCopyNotification from './QuoteCopy/QuoteCopyNotification';
import QuoteUpdateNotification from './QuoteUpdate/QuoteUpdateNotification';
import QuoteSubmitNotification from './QuoteSubmit/QuoteSubmitNotification';
import NewFireDepartmentNotification from "@components/NotificationsListItem/FireDepartment/NewFireDepartmentNotification";
import NewsNotification from "@components/NotificationsListItem/News/NewsNotification";
import CustomNotification from "@components/NotificationsListItem/Custom/CustomNotification";
import MessageNotification from "@components/NotificationsListItem/Message/MessageNotification";

const components = {
	'order-status': OrderStatusNotification,
	'quote-update': QuoteUpdateNotification,
	'quote-share': QuoteShareNotification,
	'quote-copy': QuoteCopyNotification,
	'quote-submitted': QuoteSubmitNotification,
	'new-fire-department': NewFireDepartmentNotification,
	'news': NewsNotification,
	'general': NewsNotification,
	'custom': CustomNotification,
	'new-message': MessageNotification,
	'option-update': NewsNotification,
};

const NotificationsListItem = ({
	item: notification,
	className = null,
	actionsClassName = null,
	onRemove = () => {},
	hideMenu = false,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const Component = components[notification.notification_type];
	/**
	 * Notification Menu Actions
	 */

	const menuActions = useMemo(() => {
		return [
			{
				label: 'Dismiss',
				action: () => onRemove(notification.notification_id),
			},
		];
	}, [onRemove, notification]);

	/**
	 * Get Notification Button Action
	 */

	const hasNotificationAction = useCallback(
		(notification) => {
			switch (notification.notification_type) {
				case 'quote-update':
				case 'quote-share':
				case 'quote-copy':
					return !!notification.quote_id;
				case 'order-status':
				case 'new-message':
					return !!notification.order_id;
				case 'new-fire-department':
					return true;
				case 'news':
				case 'general':
				case 'option-update':
					return !!notification.news_url;
				default:
					return false;
			}
		},
		[],
	);
	const doNotificationAction = useCallback(
		(notification) => {
			switch (notification.notification_type) {
				case 'quote-update':
				case 'quote-share':
				case 'quote-copy': {
					dispatch(
						setConfiguratorOptions({
							quoteId: notification.quote_id,
						}),
					);
					break;
				}
				case 'order-status':
				case 'new-message': {
					history.push(`/orders/${notification.order_id}`);
					break;
				}
				case 'new-fire-department':
					window
						.open(
							`${API_ROOT}/iris/notifications/${notification.notification_id}`,
							'_blank',
						)
						.focus();
					break;
				case 'news':
				case 'option-update':
				case 'general': {
					return notification.news_url
						? window.open(notification.news_url, '_blank')
						: null;
				}
				default:
					return null;
			}
		},
		[history, dispatch],
	);

	return (
		<div
			className={classNames({
				[styles.notificationItem]: true,
				[className]: !!className,
			})}>
			<div className={styles.notificationItemContent}>
				<Component notification={notification} />
			</div>
			<NotificationType
				onClick={() => doNotificationAction(notification)}
				className={classNames({
					[styles.notificationType]:true,
					[styles.noAction]: !hasNotificationAction(notification),
				})}
				notification={notification}
			/>
			{!hideMenu && (
				<Menu
					actions={menuActions}
					className={styles.notificationItemMenu}
				/>
			)}
			{(!hideMenu || hasNotificationAction(notification)) && (
			<div
				onClick={() => doNotificationAction(notification)}

				className={classNames({
					[styles.noAction] : !hasNotificationAction(notification),
					[styles.notificationItemActions]: true,
					[styles[
						notification.notification_type.replace(
							/-([a-z])/g,
							function (g) {
								return g[1].toUpperCase();
							},
						)
					]]: true,
					[actionsClassName]: !!actionsClassName,
				})}>
				{hasNotificationAction(notification) && (
					<ArrowCircleIcon fill="#FFF" />
				)}
			</div>
			)}
		</div>
	);
};

export default NotificationsListItem;
